// Global styling.
import 'add-to-homescreen'
import 'add-to-homescreen/dist/style/addtohomescreen.css'
import 'offline-js/offline'
import 'pwacompat/pwacompat.min'
import 'react-image-lightbox/style.css'
import './app/styles/app.scss'

import i18next from 'i18next'
import { createElement } from 'react'
import { initReactI18next } from 'react-i18next'
import ReactDOM from 'react-dom'

import { App } from './app/App'
import { Status } from './app/controllers/Status'
import { stores } from './app/stores'
import { AuthStore } from './app/stores/authStore'
import { locales } from './locales'
import { detectLanguage } from './locales/detectLanguage'

// Set offline options
Offline.options.interceptRequests = false
Offline.options.requests = false

// Initialize i18next module.
i18next
  .use(initReactI18next)
  .init({
    debug: false,
    lng: stores.commonStore.language,
    fallbackLng: ['neutral', 'en-US'],
    resources: locales,
    interpolation: {
      // Not needed with React.
      escapeValue: false,
    },
  })
  .then(detectLanguage)
  .then((language): void => {
    stores.commonStore.setLanguage(language)
  })
  .then(() => {
    if (process.env.WEBPACK_DEV_SERVER) {
      const token = localStorage.getItem(AuthStore.TOKEN_STORAGE_KEY)
      if (token) {
        stores.authStore.setToken(token)
      }
    }

    new Status(stores.commonStore) // TODO: Won't this trigger the GC?

    ReactDOM.render(createElement(App), document.getElementById('app'))
  })
