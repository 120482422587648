import { ChangeEvent, ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './AcceptPrivacyPolicy.scss'
import { useEnvironment } from '../../App'

interface AcceptPrivacyPolicyProps {
  privacyPolicyAccepted: boolean
  change(accepted: boolean): void
}

export function AcceptPrivacyPolicy(
  props: AcceptPrivacyPolicyProps
): ReactElement {
  const environment = useEnvironment()
  const { t } = useTranslation()

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.change(e.target.checked)
    },
    [props]
  )

  return (
    <div
      className={`${styles.customControl} ${styles.customCheckbox} ${styles.checkboxLg}`}
    >
      <input
        className={styles.customControlInput}
        checked={props.privacyPolicyAccepted}
        id='privacyPolicyAccepted'
        name='privacyPolicyAccepted'
        onChange={changeHandler}
        type='checkbox'
      />
      <label
        htmlFor='privacyPolicyAccepted'
        className={styles.customControlLabel}
      >
        <Trans t={t} i18nKey='auth.field.acceptPrivacyPolicy'>
          I accept the{' '}
          <a
            href={encodeURI(environment.privacyPolicyUrl)}
            rel='noreferrer'
            target='_blank'
          >
            privacy policy
          </a>
          .
        </Trans>
      </label>
    </div>
  )
}
